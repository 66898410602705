import { _global } from '../config/config';
import { UserState } from '../services/reducers/userReducer';
import { LanguageSrcProps } from '../utils/constants';
import requestAPI from '../utils/requestAPI';
import { getStringByLanguage } from '../utils/utils';
import { ParticipantInfo } from './participants';
import { PaginationProps, ResponseProps, prepareRequestParams } from './utils';

const buildApiPrefix = (
  url: string,
  user: UserState['userInfo'] = _global.user,
) => {
  if (!user) throw new Error(getStringByLanguage('REQUEST_ERROR'));

  if (user.role === UserRole.Super_Admin || user.role === UserRole.Sub_Admin) {
    return `user/${url}`;
  }

  return `community/${user?.participant?.community_id}/participant/${user?.participant?.id}/user/${url}`;
};

export enum FeedbackTypes {
  Bug = 'Bug',
  Comment = 'Comment',
  Other = 'Other',
}

export const languageSrcProps = ['en', 'fr', 'es', 'nl'] as const;

export interface LanguageItem {
  value: number;
  label: string;
  src: LanguageSrcProps;
}

export const languageOptions: LanguageItem[] = [
  { value: 0x809, label: 'English (UK)', src: 'en' },
  { value: 0x40c, label: 'Français', src: 'fr' },
  { value: 0x40a, label: 'Español', src: 'es' },
  { value: 0x413, label: 'Nederlands', src: 'nl' },
];

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum UserRole {
  Participant = 'Participant',
  Community_Manager = 'Community_Manager',
  Sub_Admin = 'Sub_Admin',
  Super_Admin = 'Super_Admin',
}

export const actionManager = [
  'OwnParticipants',
  'OwnInvoices',
  'OwnDevices',
  'OwnStreams',
] as const;

export const actionManagerPro = [...actionManager] as const;

export const actionSubAdmin = [
  'SubCommunities',
  'SubParticipants',
  'SubDevices',
  'SubStreams',
] as const;

export const actionSuperAdmin = [
  'Communities',
  'Participants',
  'Invoices',
  'Devices',
  'Streams',
] as const;

export const actionRoles = [
  'Manager',
  'ManagerPro',
  'SubAdmin',
  'SuperAdmin',
] as const;

export const actionList = [
  ...actionSuperAdmin,
  ...actionSubAdmin,
  ...actionManagerPro,
  ...actionRoles,
] as const;

export type UserActions = (typeof actionList)[number];

export type UserActionProps = {
  [key in UserActions]?: number | number[] | true;
};

export const printUserRole = (role: UserRole) => {
  switch (role) {
    case UserRole.Participant:
      return getStringByLanguage('PARTICIPANT');
    case UserRole.Community_Manager:
      return getStringByLanguage('COMMUNITY_MANAGER');
    case UserRole.Sub_Admin:
      return getStringByLanguage('SUB', 'ADMIN');
    case UserRole.Super_Admin:
      return getStringByLanguage('SUPER', 'ADMIN');
    default:
      return '';
  }
};

export interface BillingHistory {
  name: string;
  email: string;
  ean: string;
  active_plan: string;
  unit_amount: number;
  unit_amount_decimal: number;
  unit_currency: string;
  status: string;
  date: string;
}

export interface UserInfo {
  id: number;
  avatar: string | null;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  gender: Gender;
  phone: string;
  ean: string;
  job_title: string;
  plan:
    | 'PRICE_PARTICIPANT'
    | 'PRICE_COMMUNITY_MANAGER'
    | 'PRICE_COMMUNITY_MANAGER_PRO';
  customer_name: string;
  billing_history: BillingHistory[];
  plan_renews_on: string;
  notifications: string; // { daily: boolean; weekly: boolean; monthly: boolean; }
  two_factor_auth?: string;
  security_logout: boolean;
  language: number;
  logo_url?: string;
  role: UserRole;
  additional_roles?: UserActionProps;
  refer?: {
    first_name: string;
    last_name: string;
    date: string;
  };
  participant?: ParticipantInfo;
  isSubAdmin?: boolean;
  company_name: string;
}

export type UserAndInviteProps = Partial<
  Pick<
    ParticipantInfo,
    | 'participant_name'
    | 'type'
    | 'participant_address'
    | 'participant_city'
    | 'participant_country'
    | 'participant_postalcode'
    | 'participant_idn'
    | 'participant_office_idn'
    | 'participant_vat'
    | 'participant_nace'
    | 'participant_lscn'
    | 'participant_lscs'
  > &
    Pick<
      UserInfo,
      | 'email'
      | 'first_name'
      | 'last_name'
      | 'role'
      | 'company_name'
      | 'gender'
      | 'phone'
      | 'additional_roles'
    >
>;

export const sendFeedback = async ({
  type,
  description,
  recaptcha,
}: {
  type: FeedbackTypes;
  description: string;
  recaptcha: string;
}) => {
  return await requestAPI.post<ResponseProps>(`user/feedback?type=${type}`, {
    description,
    recaptcha,
  });
};

export const forgotPassword = async ({
  email,
  captcha,
}: {
  email: string;
  captcha: string;
}) => {
  return await requestAPI.post<ResponseProps<UserInfo>>(
    'user/forgot-password',
    {
      email,
      captcha,
    },
  );
};

export const resetPassword = async ({
  token,
  password,
  captcha = '',
}: {
  token: string;
  password: string;
  captcha?: string;
}) => {
  return await requestAPI.post<ResponseProps<UserInfo>>(
    `user/reset-password?token=${token}`,
    {
      password,
      captcha,
    },
  );
};

export const signin = async ({
  email,
  password,
  recaptcha,
  remember,
  push_token,
}: {
  email: string;
  password: string;
  remember: boolean;
  recaptcha: string;
  push_token: string | number | boolean;
}) => {
  return await requestAPI.post<ResponseProps<UserInfo>>('user/signin', {
    email,
    password,
    remember,
    recaptcha,
    push_token,
  });
};

export const signup = async ({
  invitation,
  password,
  captcha = '',
}: {
  invitation: string;
  password: string;
  captcha?: string;
}) => {
  return await requestAPI.post<ResponseProps<UserInfo>>(
    `user/signup?invitation=${invitation}`,
    {
      password,
      captcha,
    },
  );
};

export const addUser = async (data: Partial<UserInfo>) => {
  return (await requestAPI.post)<ResponseProps<ParticipantInfo>>(
    `user/add`,
    data,
  );
};

export const inviteUser = async (id: UserInfo['id']) => {
  return (await requestAPI.post)<ResponseProps<ParticipantInfo>>(
    `user/${id}/invite`,
  );
};

export const addUserAndSendInvite = async ({
  community_id,
  data,
}: {
  community_id?: string | number | null;
  data: UserAndInviteProps;
}) => {
  community_id = community_id || _global.user?.participant?.community_id || 0;
  return (await requestAPI.post)<ResponseProps<UserInfo>>(
    `/community/${community_id}/user`,
    data,
  );
};

export const getUsers = async (data: PaginationProps<UserInfo>) => {
  let url = buildApiPrefix('all');

  const { search, page, perPage, sort, sortField } = data || {};

  url += prepareRequestParams({
    search,
    page,
    perPage,
    sort,
    sortField,
  });
  return await requestAPI.get<ResponseProps<UserInfo>>(url);
};

export const getUserInfo = async () => {
  let url = '';
  if (_global.user) {
    url = buildApiPrefix('');
  } else {
    url = `user/`;
  }
  return await requestAPI.get<ResponseProps<UserInfo>>(url);
};

export const updateUserInfo = async (
  payload: Partial<UserInfo> & {
    newAvatar?: File | null;
    logoImage?: File | null;
    currentPassword?: string;
    updatePassword?: string;
  } & Partial<
      Pick<
        ParticipantInfo,
        | 'joined'
        | 'participant_vat'
        | 'participant_address'
        | 'participant_city'
        | 'participant_country'
        | 'participant_postalcode'
        | 'participant_vat'
      >
    >,
) => {
  const { id = _global.user?.id, newAvatar, logoImage, ...data } = payload;

  let avatar = data.avatar;
  delete data.avatar;

  let logo_url = data.logo_url;
  delete data.logo_url;

  if (newAvatar || logoImage) {
    const formData = new FormData();
    newAvatar && formData.append('avatarImage', newAvatar);
    logoImage && formData.append('logoImage', logoImage);
    const response = await requestAPI.post<
      ResponseProps<{
        uploaded?: { avatarImage: string | null; logoImage: string | null };
      }>
    >(buildApiPrefix(`${id}/upload`), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const uploaded = response.data.data?.uploaded;

    if (uploaded?.avatarImage) {
      avatar = uploaded.avatarImage;
    }

    if (uploaded?.logoImage) {
      logo_url = uploaded.logoImage;
    }
  }
  return await requestAPI.patch<ResponseProps<UserInfo>>(
    buildApiPrefix(`${id}`),
    {
      ...data,
      avatar,
      logo_url,
    },
  );
};

export const deleteUser = async (id: number, forever = false) => {
  return (await requestAPI.delete)<ResponseProps<UserInfo>>(
    `/user/${id}?safeMode=${!forever}`,
  );
};

export const addUserPageLog = async (page_name: string) => {
  const id = _global.user?.id;
  return await requestAPI.post<ResponseProps>(
    `user/${id}/page-log?page_name=${encodeURIComponent(page_name)}`,
  );
};
